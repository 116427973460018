 * {
  font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}
body {
  margin: 0;
  font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.walletTopBar{
  border-bottom: 1px solid rgb(229, 232, 235);
  padding: 20px;
}
.walletTopBar span{
  font-weight: 500;
  font-size: 14px;
}

.featured-card {
  height:140px;
}

.loader-container {
  display: inline-block;
  text-align: center;
  height: 16vh;
  width: 63vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btnFacebook { 
  width: 200px;
  height:40px;  
  border-radius: 4px;
  background: #3b5998;
  color:white;
  border:0px transparent;  
  text-align: center;
  margin:5px;
  font-size: medium;
  display: inline-block;
}
.btnGoogle { 
  margin:5px;
  width: 200px;
  height:40px;
  border-radius: 4px;
 
  color:white;
  border:0px transparent;
  text-align: center;
}
.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.6;
}
.btnGoogle:hover {
 
  opacity: 0.6;
}
.btnOther { 
  margin:5px;
  width: 200px;
  height:40px;
  border-radius: 4px;
  color:rgb(247, 235, 235);
  border:0px transparent;
  text-align: center;
}
.btnOther:hover {

  opacity: 0.6;
}
a {
  text-decoration:none !important;
  color: rgb(49, 72, 250);
}

.LoginButton {
  border-bottom:'1px solid rgb(229, 232, 235)'
}
.AddressBox {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(138, 147, 155, 0.06);
  border-radius: 5px;
  border: 1px solid rgb(229, 232, 235);
  display: flex;
  height: 48px;
  padding: 0px 12px;
}
.AddressText {
  overflow: auto;
  white-space: nowrap;
  margin-right: 60px;
}
.AddressCopyIcon {
  border: none;
  background-color: transparent;
  font-size: 12px;
  cursor: pointer;
  color: rgb(112, 122, 131);
}
.AddressIcon {
  font-size: 18px;
}



.card-heading {
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  color: rgb(4, 17, 29);
  border-bottom: #d3d3d3 solid 1px;
  padding: 0px;

  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
 
  user-select: none;
  background-color: rgb(255, 255, 255);
}
.card-heading .MuiSvgIcon-root {
  font-size: 1.4rem;
  margin-right: 5px;
}
.description-trade > p {
  padding: 10px;
  padding-left: 0px;
  overflow: auto;
  width: 100%;
  height: 80%;
  font-size: 14px;
}