.MuiDropzoneArea-root:hover {
border-color: #2196f3;
}
.nft-image-dropbox {
    padding:10px;
}
.material-icons-sharp {
    font-family: 'Material Icons Sharp';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
.btnFacebook{
    font-size: 14px !important;
}
.__react_modal_image__modal_container{
    z-index: 1111111 !important;
}
.btnFacebook .fa-facebook{
    font-size: 20px !important;
    margin-right: 20px !important;
}
.cur-auto{
    cursor: auto  !important;
}
a{
    width: fit-content !important;
}
.asset-cell-image {
    border-radius: 4px;
    border: 1px
 solid rgb(229, 232, 235);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex: 0 0 auto;
    height: 48px;
    width: 48px;
}
.grid_title{
    margin-top: 50px !important;
}
.grid_title_exp{
    margin-top: 50px !important;
}
.asset-image {
    object-fit: contain;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.asset-cell-collectionName {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 12px;
    color: rgb(112, 122, 131);
    white-space: nowrap;
    margin: 0;
}
.asset-cell-nftName {

}
.accordion-button:focus{
    box-shadow: inset 0 -1px 0 rgb(244 248 247 / 25%) !important;
}
.accordion-body {
    background-color:#f0f8ff7a !important;
}
.MuiSvgIcon-colorPrimary {
    color: #027BFE;
}
.NikiCategoryMenu-item-44:hover{
    text-decoration: underline !important;
}
.marginOpx .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px) !important;
}
.profileBg:hover{
    background-color: rgba(60,64,67,0.08);
}
.top_nav_marg{
    margin-top: 6px;
}
.DetailsTable > tr > td {
    padding-right: 10px;
}
.top-content .PlainNavigationMenu-item-18:hover {
    text-decoration: underline !important;
    text-decoration-color: #0084ff !important;
    text-decoration-thickness: 3px !important;
    text-underline-offset: 20px !important;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
}

.profileImg:hover .middle {
    opacity: 10;
}
.jss20{
height: auto !important;
}
.text {
    /* background-color: #04AA6D; */
    color: white;
    /* font-size: 16px;
    padding: 16px 32px; */
}


/* .profileImg:hover {
    cursor: pointer;
    opacity: 0.3;
  } */

.react-multi-carousel-list {
    position: inherit !important;
}

.bgAssetPage .react-multiple-carousel__arrow--right {
    right: calc(7% + 1px) !important;
}
.assetPage .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px) !important;
}

.bgAssetPage .react-multiple-carousel__arrow--left {
    left: calc(7% + 1px) !important;
}
.assetPage .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px) !important;
}
.homePageCarousel {
    box-shadow: none !important;
    border: none !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
    position: relative;
}
.homePageCarousel .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px) !important;
}
.homePageCarousel .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px) !important;
}
.bgAssetPage {
    margin-top: 2%;
    background: #fff;
}
.accordion-body .MuiButton-outlined {
    background-color: aliceblue;
    border: 1px solid #51c4e2;
}
.accordion-button {
    background: #fff !important;
    color: black !important;
    font-size: 16px;
    font-weight: 600;
} 
.accordion-button .MuiSvgIcon-root {
    font-size: 1.4rem;
    margin-right: 10px;
}

.jss17:hover {
    text-decoration: underline !important;
    text-decoration-color: #0084ff !important;
    text-decoration-thickness: 3px !important;
    text-underline-offset: 20px !important;
}

.jss17:focus {
    text-decoration: underline !important;
    text-decoration-color: #0084ff !important;
    text-decoration-thickness: 3px !important;
    text-underline-offset: 20px !important;
}
header .frjscc{
    margin-left: 10px !important;
}
.logo-image {
    /* width: 12%; */
}

.logo-image img {
    width: 150px;
}
.jss45 {
    color: rgba(0, 0, 0, 0.87) !important;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 300;
}
.NikiCategoryMenu-item-45{
    font-size: .9em !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-align: inherit !important;
}
.NikiCategoryMenu-item-45:hover {
    color: #1d1d1f !important;
    text-decoration: underline !important;
}
.NikiCategoryMenu-item-35{
    font-size: .9em !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-align: inherit !important;
}
.NikiCategoryMenu-item-35:hover {
    color: #1d1d1f !important;
    text-decoration: underline !important;
}
.logo-image-text {
    display: none;
}

.NikiCategoryMenu-item-327 {
    font-family: inherit !important;
    font-weight: 400;
    line-height: inherit !important;
    text-align: inherit !important;
    color: #515154;
    font-size: 0.8rem;
}

.arrow-top .MuiIconButton-root {
    margin-top: -3%;
    font-size: 30px !important;
    background-color: lightgray;
}

.logo-text {
    display: none;
}

.makeStyles-sectionDesktop-10 {
    display: flex;
}

.copy-right ul li {
    display: inline-block;
}
.navHref{
    text-decoration: none !important;
    justify-content: center !important;
    text-align: center;
    color: rgba(0, 0, 0, 0.54) !important;
}
.navHref:hover{
    text-decoration: underline !important;
    text-decoration-thickness: 3px !important;
    text-underline-offset: 20px !important;
    text-decoration-color: #0084ff !important;
}
.top-content .PlainNavigationMenu-item-18:hover{
    text-decoration: none !important;
}
.jss17:hover{
    text-decoration: none !important;
}
.copy-right ul li a {
    padding: 10px;
}

.footer-log img {
    margin-top: 0%;
    width: 130px;
}

.NikiCategoryMenu-title-49 {
    text-transform: capitalize !important;
}
.NikiCategoryMenu-title-44{
    text-transform: capitalize !important;
}
.NikiCategoryMenu-item-50 {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: gray !important;
    margin-bottom: 5px;
}

.NikiCategoryMenu-item-50:hover {
    text-decoration: rgb(126, 120, 120) !important;
    text-decoration-line: underline !important;
    text-decoration-thickness: 1px !important;
    text-underline-offset: 3px !important;
}

.expand-bar {
    display: none;
}

.cover-button .btn {
    float: right;
    position: absolute;
    right: 10%;
}

.profile-img-top img {
    width: 10%;
    z-index: 99;
    opacity: 1;
    overflow: hidden;
}

.uploader-picker {
    margin-top: 7%;
}

.img-profile-center {
    text-align: center;
}

.img-profile-center .MuiBadge-root {
    text-align: center;
    margin-top: -4rem;
    position: relative;
}

.profile-name {
    text-align: center;
    color: black;
    font-weight: 500;
    margin-left: 6%;
}

.description-name {
    text-align: center;
    margin-top: 2%;
    margin-left: 6%;
}

.description-name p {
    font-size: 14px;
    color: gray;
}

.user-name-profile {
    text-align: center;
}

.user-name-profile p {
    text-align: center;
    margin-top: 4%;
    font-size: 16px;
}
.tabslink-top b.b {
    font-weight: 600 !important;
    font-size: 16px !important;
}

.tabslink-top {
    text-align: left;
    /* margin-top: 10%; */
}
.tabslink-top p {
    font-size: 16px !important;
    font-weight: 600;
}

.tabslink-top .nav-pills .nav-link.active,
.nav-pills .show .nav-link {
    text-align: left;
    width: 100%;
    /* margin-left: 26%; */
}

.tabslink-top .tabslink-top .nav-pills .nav-link.active,
.nav-pills .show .nav-link {
    display: inline;
    width: 50%;
    text-align: left;
    width: 50%;
    margin-left: 23% !important;
}

.user-name-profile img {
    width: 100%;
}

.NikiCategoryMenu-item-164 {
    text-decoration: none !important;
}

.container-cmd {
    margin-top: 5rem;
}

.cards-iii .MuiGrid-grid-md-3 {
    display: contents;
    margin-top: 5%;
}

.cards-iii {
    margin-top: 10%;
}

.NikiCategoryMenu-item-168 {
    text-decoration: none;
}

.jss168 {
    text-decoration: none !important;
}

.TutorInfo-title-50 {
    font-size: 15px !important;
}

.makeStyles-menuPaper-12 {
    margin-top: 1.6%;
    padding-bottom: 10px !important;
    padding: 2%;
}

.modal.show .modal-dialog {
    top: 10%;
}

.id-profile {
    padding: 5%;
    font-size: 16px;
}

.id-profile p {
    margin-bottom: 0%;
}

.id-profile span {
    color: #007bff;
    margin-top: 0%;
    font-size: 14px;
}

.deposit-btn {
    margin-top: 5%;
}

.deposit-btn .btn {
    background-color: lightgray;
}


/* .Menu-butons {
}
.Menu-butons .btn { 
    text-align: center;
    width: 50%;
    margin-top: 2%;
    border-radius: 20px;
    background-color: lightgray;
} */

.makeStyles-menuItems-13 {
    padding-top: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 5% !important;
    border-radius: 20px !important;
    border: 1px solid gray !important;
    padding: 10px !important;
}

.jss13 {
    padding-top: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px;
    margin-bottom: 5% !important;
    border-radius: 20px !important;
    border: 1px solid gray !important;
    padding: 10px !important;
}

.jss12 {
    padding: 2% !important;
}

.jss12 {
    margin-top: 2% !important;
}

.status-accordion,
.price-accordion,
.collection-accordion,
.category-accordion {
    margin-bottom: 5%;
}
.formControl {
    margin: 1;
    min-width: 120;
  }
  .MuiSelect-select:focus{
      background: #fff !important;
      border: #007bff;
  }
  .MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
}
.price-accordion select {
    /* width: 100%;
    height: 35px; */
    width: 100% ;
    height: 45px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border: solid 1px #fafafa;
}
.MuiButton-outlinedPrimary {
    /* color: #3f51b5; */
    border: 1px solid #E7EDF3 !important;
}
.input-amount {
    text-align: center;
    padding-top: 5px;
    display: inline;
}

.input-amount input {
    width: 100px;
    height: 35px;
    display: inline;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5px;
}

.MuiAccordionDetails-root {
    display: block !important;
}

.filters-top {
    margin-bottom: 3%;
    font-weight: 600;
}
.filters-top a {
    font-size: 22px;
    font-weight: 600;
}

.img-src img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.title-text {
    font-size: 22px;
    font-weight: 600;
    padding: 10px;
}

.title-text p {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
    font-size: 1rem;
}

.right-content-data ul li {
    display: inline-block;
    margin: 2%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
}

.right-content-data ul {
    margin-left: -8%;
}
.react-multiple-carousel__arrow{
    background: #fff !important;
    box-shadow: rgb(4 17 29 / 25%) 0px 0px 2px 0px !important;
    opacity: 1 !important;
}
.react-multiple-carousel__arrow:before{
    font-size: 14px !important;
    color: rgb(112, 122, 131) !important;
}
.react-multiple-carousel__arrow:before :hover{
    color: #027BFE !important;
}
.price-buy ul {
    border: 1px solid lightgray;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
    margin-left: 0%;
    border-radius: 10px;
}

.price-buy ul li a p {
    margin-bottom: 0%;
}

.price-buy ul li a .buy-span {
    text-align: center;
}

.history-trade {
    margin-top: 5%;
    border: 1px solid lightgray;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
    border-radius: 10px;
    padding: 2%;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
    /* font-size: 1rem; */
    height: 100%;
}

.makeStyles-sectionDesktop-11 {
    display: block !important;
    color: black;
    /* margin-left: 45px; */
}

.registered-sign {
    text-align: center;
}

.registered-sign {
    /* font-size: 22px;
    font-weight: 500; */
}

.forms-tag {
    text-align: center;
    background-color: blanchedalmond;
    justify-content: center;
}

.forms-tag {
    height: 500px;
    padding-top: 121px;
    border-radius: 20px;
    margin-top: 23px;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
    background-color: white;
}

.forms-tag .form-control {
    width: 60%;
    display: inline-block;
}

.log-in .light-shadow {
    padding: 5px 8px;
    /* border-radius: 20px; */
    border: 1px solid #0069d9;
    /* font-size: 1rem; */
    background-color: white;
    color: #0069d9;
    margin-bottom: 2%;
    text-transform: capitalize !important;
}

.log-in .light-shadow:hover {
    background-color: #0069d9;
    color: white;
}
.BootstrapEmailSubscribe-submit-40:hover{
    background-color: #0069d9 !important;
}
.btn-outlined:hover{
    background-color: #fff !important;
    border: solid 1px  #0069d9 !important; 
}
.MuiButton-outlined{
    border: solid 1px  #0069d9 !important; 
    text-transform: capitalize !important;
}
.MuiButton-containedPrimary:hover{
    background-color: #0069d9 !important;
}
.BootstrapEmailSubscribe-submit-40{
    border-color: #027BFE!important;
    background-color: #027BFE!important;
}
.MuiButton-containedPrimary {
    border-color: #027BFE!important;
    background-color: #027BFE!important;
}
.MuiButton-contained{
    text-transform: capitalize !important;
}
.facebook-icon .light-shadow {
    border-radius: 20px;
    border: 1px solid gray;
    font-size: 1rem;
    background-color: white;
    color: black;
    margin-bottom: 2%;
}

.facebook-icon .light-shadow:hover,
.google-icon .light-shadow:hover,
.email-icon .light-shadow:hover,
.meta-icon .light-shadow:hover {
    background-color: #4267B2;
    color: white;
}

.facebook-icon img {
    width: 5%;
    margin-right: 3%;
}

.google-icon .light-shadow {
    width: 60%;
    border-radius: 20px;
    border: 1px solid gray;
    font-size: 1rem;
    background-color: white;
    color: black;
    margin-bottom: 2%;
}

.google-icon img {
    width: 5%;
    margin-right: 4%;
}

.email-icon .light-shadow {
    width: 60%;
    /* border-radius: 20px; */
    /* border: 1px solid gray; */
    font-size: 1rem;
    background-color: white;
    color: black;
    margin-bottom: 2%;
}

.email-icon img {
    width: 5%;
    margin-right: 7%;
}

.meta-icon .light-shadow {
    width: 60%;
    border-radius: 20px;
    border: 1px solid gray;
    font-size: 1rem;
    background-color: white;
    color: black;
    margin-bottom: 2%;
}

.forms-tag span {
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
    /* font-size: 1rem; */
    margin-bottom: 3%;
}

.meta-icon img {
    width: 5%;
    margin-right: 5px;
}

.log-in img {
    width: 5%;
    margin-right: 5px;
}

.makeStyles-card-51 {
    background-color: rgb(251, 253, 255);
    border-radius: 15px;
    z-index: 2;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.makeStyles-card-92 {
    background-color: rgb(251, 253, 255);
    border-radius: 15px;
    z-index: 2;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%)
}

.makeStyles-card-130 {
    background-color: rgb(251, 253, 255);
    border-radius: 15px;
    z-index: 2;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%)
}

.makeStyles-card-134 {
    border: none;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%)
}

.makeStyles-smCardBox-70 {
    border: none !important;
    background-color: rgb(251, 253, 255);
    border-radius: 15px;
    z-index: 2;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.makeStyles-card-33 {
    border: none !important;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.makeStyles-card-64 {
    border: none !important;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.MuiTypography-h3 {
    font-size: 28px !important;
    font-weight: 400 !important;
}

.MuiTypography-h3 .MuiBox-root-24,
.MuiTypography-h3 .MuiBox-root-25,
.MuiTypography-h3 .MuiBox-root-26,
.MuiTypography-h3 .MuiBox-root-27,
.MuiTypography-h3 .MuiBox-root-28 {
    font-size: 22px !important;
    font-weight: 600 !important;
}

.MuiTypography-body1 {
    font-size: 12px !important;
    font-weight: 600 !important;
}

.search-bar {
    position: fixed;
    background-color: #fafafa;
    z-index: 99;
    padding: 1%;
    top: 64px;
}

.makeStyles-search-20 {
    background-color: white !important;
}

.sidebar-fix {
    z-index: 99;
    /* margin-bottom: 50%;
    height: 41rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1%; */
}

.tab-content {
    /* margin-top: 5%; */
}

.bio-general {
    margin-top: 20px;
}

.bio-general .form-group {
    margin-top: 3%;
}

.bio-general .form-label {
    font-size: 16px;
    font-weight: 500;
}

.form-control {
    font-size: 15px;
    color: gray;
}

.form-control:focus {
    box-shadow: none;
}

.flex-column {
    margin-top: 5%;
}

.makeStyles-search-141 {
    background-color: white;
}

.buy-span .light-shadow {
    width: 100px;
    color: black;
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 10px;
    outline: none;
    margin-top: 5%;
}

.buy-span .light-shadow:hover {
    background-color: #007bff;
    color: white;
}

.market-data {
    margin-top: 5%;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    display: none;
}

.right-content-data ul li a span {
    font-size: 18px;
    font-weight: 500;
    color: black;
}

.price-buy ul li a span {
    font-size: 15px;
    font-weight: 500;
}

.history-trade span {
    font-size: 16px;
    font-weight: 600;
    color: black;
}

.data-tables .table {
    height: 250px;
}

.img-src {
    display:flex;
    justify-content: center;
    align-items:center;
    text-align: center;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid lightgray;
    /* height: 470px; */
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.image-gallery-slide.center {
    position: relative !important;
    height: 360px !important;
}

.image-gallery-slide {
    width: 120% !important;
}

.makeStyles-card-73 {
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.makeStyles-smCardBox-91 {
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.makeStyles-card-113 {
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.makeStyles-card-155 {
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.makeStyles-card-151 {
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.makeStyles-card-28 {
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 50px !important;
    width: 25px !important;
}

.card-carousel {
    margin-top: 10%;
    border: 1px solid lightgray;
    padding: 2%;
    border-radius: 10px;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
}

.card-carousel p {
    font-size: 16px;
    font-weight: 600;
}

.check-boxs .form-check {
    display: inline-block !important;
}

.check-boxs label {
    font-size: 12px;
}

.forms-tag p {
    /* font-size: 22px;
    font-weight: 600; */
}

.check-boxs .form-check-input {
    width: 1rem;
    height: 1rem;
    margin-top: .4em;
}

::-webkit-scrollbar {
    width: 4px;
}

 ::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    height: 20px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.NikiCategoryMenu-item-49 {
    font-size: 1em !important;
    font-weight: inherit !important;
}

.tabs-cards-component {
    /* height: 90%;
    overflow: scroll;
    overflow-x: hidden; */
}


/* .market-data {
    height: 600px;
    overflow: scroll;
    overflow-x: hidden;
  } */

.description-trade {
    border: 1px solid lightgray;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
    border-radius: 10px;
    padding: 3%;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 400;
    /* font-size: 1rem; */
    height: 250px;
    margin-bottom: 0px;
}


/* .description-trade span {
    font-size: 18px;
    font-weight: 500;
    color: #ccc;
} */

button.description-trade span {
    font-size: 20px;
    color: #fff;
}

.wallet-funds {
    text-align: center;
    margin-top: 5%;
}

.wallet-funds .light-shadow {
    background-color: #007bff;
    color: white;
    outline: none;
    width: 50%;
}

.img-src-card img {
    position: absolute;
    /* width: auto; */
    /* vertical-align: middle; */
    /* height: 52%; */
    border-radius: 50%;
    width: 26%;
}

.card-profileuser {
    margin-bottom: 5%;
}

.card {
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 2%);
    border: none;
    height: 100%;
    padding: 5%;
    margin-bottom: 5%;
}

.card-body {
    padding: 0%;
}

.card-profileuser .card-body {
    padding: 5%;
    height: 100%;
}

.user-name {
    font-size: 14px;
    font-weight: 500;
}

.email-user {
    color: #007bff;
    font-size: 11px;
}

.bio-data {
    margin-top: 3%;
    font-size: 11px;
    color: gray;
}

.change-data .light-shadow {
    padding: 0%;
    width: 37%;
    float: right;
    margin-top: 10%;
    font-size: 12px;
}

.footer-text .NikiCategoryMenu-item-231 {
    display: block;
    font-size: 1em;
    font-weight: inherit;
}

.support-footer .MuiTypography-body1 {
    font-size: 12px !important;
    font-weight: 600 !important;
    margin-top: 16px;
    text-transform: capitalize;
}

.MuiAccordion-rounded {
    margin-bottom: 5%;
}

.history-trade .table-responsive {
    margin-bottom: -5%;
}

.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3,
.video-js.vjs-9-16,
.video-js.vjs-1-1 {
    height: 410px !important;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: lightgray;
    font-size: 14px;
}

.accordion-property {
    margin-top: 10%;
    height: 400px;
}

.button-property ul li {
    padding: 5px;
    display: inline-block;
}

.accordion-link ul {
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 10px;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 5%);
    cursor: pointer;
}

.accordion-link ul:hover {
    background-color: #007bff;
    color: white;
}

.accordion-link ul li {
    display: block;
    text-align: center;
    margin-top: 20px;
}

.accordion-link ul li a {
    text-align: center;
    margin-top: 5px;
    text-align: 16px;
    color: black;
    font-weight: 400;
}

.accordion-link ul li a p {
    margin-top: 5px;
    font-size: 14px;
    color: gray;
}

.asset-title {
    margin-top: 5rem;
    margin-bottom: 10px;
}

.asset-title p {
    font-size: 22px;
    font-weight: 600;
}

.serach-sorts .makeStyles-searchIcon-7 {
    padding: 0px 9px;
    top: 0px !important;
}
.serach-sorts {
    width: 100%;
}
.serach-sorts .form-control {
    padding: .375rem 2.75rem;
    border-radius: 5px !important;
}
.serach-sorts .form-control option {
    padding: 20px;
}
.p-2{
    position: absolute;
    width: 100%;
    left: 0px;
    border-radius: 5px;
    box-shadow: none !important;
    top: 0;
    padding-left: 33px !important;
}

.p-2 .mt-1{
    margin-top: 43px !important;
}
.p-2 .rounded-sm{
    margin-top: 43px;
    border: 1px solid rgb(229, 232, 235); 
    padding: 0px !important;
}
.leading-4:focus {
    outline: none !important;
}
.rounded-sm .ml-4{
    padding: 0px !important;
}
.text-gray-400{
    padding: .5rem!important;
}
.cursor-pointer{
    cursor: pointer;
    padding: .5rem!important;
    border-bottom: 1px solid rgb(229, 232, 235);
}
.cursor-pointer:hover{
    /* text-decoration: underline; */
    background: #fbfdff;
    box-shadow: 0px -4px 7px rgb(240 245 251), 0 5px 7px rgba(240 245 251);
    /* box-shadow: 0px -4px 3px rgb(247 248 249); */
}
.p-2 li{
    /* margin-top: 5px; */
    list-style-type: none;
    order: 4;
    border-bottom:1px solid rgb(229, 232, 235);
    /* border: 1px solid rgb(229, 232, 235); */

}
.text-gray-400{
    /* font-weight: 600; */
    color: rgb(112, 122, 131) !important;
}
.jZkBQk{
    height: 32px !important;
}
.serach-sorts .jZkBQk > .wrapper {
    border-radius: 5px !important;
    box-shadow: none !important;
}
.frjscc{
    min-height: 35px !important;
}
.jZkBQk > .wrapper{
    width: 94% !important;
}
.multiple-picker {
    border: 1px solid lightgray;
    border-style: dashed;
    padding: 50px;
    text-align: center;
    border-radius: 10px;
}

.file-title p {
    font-size: 16px;
    font-weight: 600;
    color: gray;
}

.multiple-pickers.btn.light-shadow {
    text-align: center;
    font-size: 12px;
    background-color: #007bff;
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 6px 0px #89bcf3;
    width: 100%;
    vertical-align: middle;
}

.accordion-link-fix {
    height: 100%;
}
/* h3 {
    margin-left: 15px;
  } */

.fileInput {
    border: 1px solid lightgray;
    border-style: dashed;
    padding: 50px;
    text-align: center;
    border-radius: 10px;
    width: 100%;
    background-color: white;
}

.imgPreview {
    text-align: center;
    margin: 5px 15px;
    height: 100%;
}

.imgPreview img {
    width: 80%;
    height: 180px;
    object-fit: contain;
    margin-top: 5px;
}

.previewText {
    width: 100%;
    margin-top: 20px;
}

.submitButton {
    padding: 12px;
    margin-left: 10px;
    background: #fff;
    border: 4px solid #d3d3d3;
    border-radius: 15px;
    font-weight: 700;
    font-size: 10pt;
    cursor: pointer;
}

.submitButton:hover {
    background: #efefef;
}

.centerText {
    text-align: center;
    width: 500px;
}

.imgPreview .card-text:last-child {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
}

.imgPreview .card {
    background-color: white;
    border: none;
    height: 290px;
    padding: 5%;
    margin-bottom: 5%;
    border-radius: 10px;
    border: 1px solid lightgray;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 5%);
}

.title-detail .description-trade {
    height: 100%;
    margin-bottom: 20px;
}

.accordion-label label {
    color: gray;
}

.card-text p {
    color: gray;
    margin-bottom: 2px;
    margin-top: 5px;
}

.card-text label {
    font-size: 14px;
    font-weight: 500;
}

.img-previews {
    height: 200px;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.title-detail {
    margin-top: -150px;
}

.file-title-fix {
    margin-top: 0px;
}

.file-title-fix label {
    font-size: 16px;
    color: gray;
    margin-bottom: 10px;
}

.accordion-label p {
    margin-bottom: 0px;
}

.border-styled {
    border-bottom-style: dashed;
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
}

.border-styled .multiple-pickers {
    text-align: right;
}

.modal-header {
    justify-content: center;
}

.save-button {
    text-align: center;
    margin-top: 10px;
}

.save-button .light-shadow {
    text-align: center;
    font-size: 12px;
    background-color: #007bff;
    border-radius: 10px;
    color: white;
    -webkit-box-shadow: 0 3px 6px 0px #89bcf3;
    width: 30%;
    vertical-align: middle;
    justify-content: center;
    padding: 10px;
}

.save-button a {
    color: white;
    font-size: 12px;
}

.form-group label {
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 5px;
}

.form-group .form-control {
    border-radius: 10px;
}

.popup-modals .modal.show .modal-dialog {
    top: 10% !important;
}

.popup-modals .modal-body p {
    font-size: 16px !important;
    color: gray !important;
}

.title-detail .form-control {
    padding: 0.7rem .75rem;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 5%);
    border-radius: 10px;
}

.modal-content {
    border-radius: 10px;
}

.collection-title p {
    margin-bottom: 5px;
}

.collection-title label {
    color: gray;
}

.create-collect {
    margin-top: 20px;
}

.create-collect .btn.light-shadow {
    border-radius: 6px;
}

.create-assets .btn.light-shadow {
    width: 100%;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 500;
    color: black;
    border: 1px solid #d8eaf5;
    padding: .375rem 0.75rem;
}

.MuiDropzoneArea-text {
    margin-top: 24px;
    margin-bottom: 24px;
}

.MuiDropzoneArea-root {
    width: 100%;
    border: dashed;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    min-height: 325px;
    border-color: rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
}

.create-assets .btn.light-shadow:hover,
.create-assets .btn.light-shadow:focus {
    border: 1px solid #d8eaf5;
    background-color: #d8eaf5;
    outline: none;
    box-shadow: none;
}

.create-assets label {
    font-size: 16px;
    font-weight: 400;
    color: gray;
}

.create-assets p {
    margin-bottom: 5px;
}

.summary-price {
    margin-top: 20px;
}

.price-section {
    padding: 5px;
    border: 1px solid lightgray;
    border-radius: 10px;
    height: 100%;
    background: #fff;
}

.summary-section {
    padding: 5px;
    border: 2px solid lightgray;
    border-radius: 10px;
    background-color: #eaf4fa;
}

.summary-text {
    padding: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0px;
}

.summary-section hr {
    margin-top: 0px !important;
}

.count-price {
    padding: 10px;
}

.count-price p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}

.count-price label {
    color: gray;
    font-size: 16px;
    font-weight: 400;
}

.count-number {
    padding: 10px;
    vertical-align: middle;
    margin-top: 5px;
}

.price-text {
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 0px;
}

.count-number {
    text-align: center;
}

.count-number label {
    font-size: 16px;
}

.post-listing {
    text-align: center;
}

.post-listing .btn.light-shadow {
    border: 1px solid blue;
    /*border-radius: 10px;*/
    width: 60%;
    margin-bottom: 10px;
}

.post-listing .btn.light-shadow:hover,
.post-listing .btn.light-shadow:focus {
    border: 1px solid blue;
    outline: none;
    box-shadow: none;
    background-color: lightskyblue;
}

.text-descriptions {
    padding: 10px;
    color: gray;
    margin-top: 0px;
}

.material-toggle {
    position: relative;
    top: 30px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: block;
    height: 61px;
    width: 1px;
}

.material-toggle input:empty {
    margin-left: -9999px;
    display: none;
}

.material-toggle input:empty~label {
    position: relative;
    float: left;
    width: 150px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.material-toggle input:empty~label:before,
.material-toggle input:empty~label:after {
    position: absolute;
    display: block;
    content: " ";
    -webkit-transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.material-toggle input:empty~label:before {
    top: 3px;
    left: 0px;
    width: 50px;
    height: 20px;
    border-radius: 12px;
    background-color: #bdbdbd;
}

input.switch:empty~label:after {
    top: 0px;
    left: 23px;
    width: 1.4em;
    height: 8px;
    bottom: 0.1em;
    margin-left: 0.1em;
    background-color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: solid 2px;
    border-color: #fff;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.material-toggle input:checked~label:before {
    background-color: #1e88e5;
}

.material-toggle input:checked~label:after {
    left: 15px;
    background-color: #1565c0;
    border-color: #1565c0;
}

.pure-material-slider {
    --pure-material-safari-helper1: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.04);
    --pure-material-safari-helper2: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.12);
    --pure-material-safari-helper3: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.16);
    --pure-material-safari-helper4: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.24);
    display: inline-block;
    width: 100%;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
}


/* Input */

.pure-material-slider>input {
    -webkit-appearance: none;
    position: relative;
    top: 24px;
    display: block;
    margin: 0 0 -36px;
    width: 100%;
    height: 36px;
    background-color: transparent;
    cursor: pointer;
}


/* Without Span */

.pure-material-slider>input:last-child {
    position: static;
    margin: 0;
}


/* Span */

.pure-material-slider>span {
    display: inline-block;
    margin-bottom: 36px;
}


/* Focus */

.pure-material-slider>input:focus {
    outline: none;
}


/* Disabled */

.pure-material-slider>input:disabled {
    cursor: default;
    opacity: 0.38;
}

.pure-material-slider>input:disabled+span {
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}


/* Webkit | Track */

.pure-material-slider>input::-webkit-slider-runnable-track {
    margin: 17px 0;
    border-radius: 1px;
    width: 100%;
    height: 2px;
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.24);
}


/* Webkit | Thumb */

.pure-material-slider>input::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 50%;
    height: 2px;
    width: 2px;
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
    transform: scale(6, 6);
    transition: box-shadow 0.2s;
}


/* Webkit | Hover, Focus */

.pure-material-slider:hover>input::-webkit-slider-thumb {
    box-shadow: 0 0 0 2px var(--pure-material-safari-helper1);
}

.pure-material-slider>input:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 2px var(--pure-material-safari-helper2);
}

.pure-material-slider:hover>input:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 2px var(--pure-material-safari-helper3);
}


/* Webkit | Active */

.pure-material-slider>input:active::-webkit-slider-thumb {
    box-shadow: 0 0 0 2px var(--pure-material-safari-helper4) !important;
}


/* Webkit | Disabled */

.pure-material-slider>input:disabled::-webkit-slider-runnable-track {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

.pure-material-slider>input:disabled::-webkit-slider-thumb {
    background-color: rgb(var(--pure-material-onsurface-rgb, 0, 0, 0));
    color: rgb(var(--pure-material-surface-rgb, 255, 255, 255));
    /* Safari */
    box-shadow: 0 0 0 1px rgb(var(--pure-material-surface-rgb, 255, 255, 255)) !important;
    transform: scale(4, 4);
}


/* Moz | Track */

.pure-material-slider>input::-moz-range-track {
    margin: 17px 0;
    border-radius: 1px;
    width: 100%;
    height: 2px;
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.24);
}


/* Moz | Thumb */

.pure-material-slider>input::-moz-range-thumb {
    appearance: none;
    -moz-appearance: none;
    border: none;
    border-radius: 50%;
    height: 2px;
    width: 2px;
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
    transform: scale(6, 6);
    transition: box-shadow 0.2s;
}


/* Moz | Progress */

.pure-material-slider>input::-moz-range-progress {
    border-radius: 1px;
    height: 2px;
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}


/* Moz | Hover, Focus */

.pure-material-slider:hover>input:hover::-moz-range-thumb {
    box-shadow: 0 0 0 2px rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.04);
}

.pure-material-slider>input:focus::-moz-range-thumb {
    box-shadow: 0 0 0 2px rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.12);
}

.pure-material-slider:hover>input:focus::-moz-range-thumb {
    box-shadow: 0 0 0 2px rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.16);
}


/* Moz | Active */

.pure-material-slider>input:active::-moz-range-thumb {
    box-shadow: 0 0 0 2px rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.24) !important;
}


/* Moz | Disabled */

.pure-material-slider>input:disabled::-moz-range-track {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

.pure-material-slider>input:disabled::-moz-range-progress {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
}

.pure-material-slider>input:disabled::-moz-range-thumb {
    background-color: rgb(var(--pure-material-onsurface-rgb, 0, 0, 0));
    box-shadow: 0 0 0 1px rgb(var(--pure-material-surface-rgb, 255, 255, 255)) !important;
    transform: scale(4, 4);
}

.pure-material-slider>input::-moz-focus-outer {
    border: none;
}


/* MS | Track */

.pure-material-slider>input::-ms-track {
    box-sizing: border-box;
    margin: 17px 0;
    border: none;
    border-radius: 1px;
    padding: 0 17px;
    width: 100%;
    height: 2px;
    background-color: transparent;
}

.pure-material-slider>input::-ms-fill-lower {
    border-radius: 1px;
    height: 2px;
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}


/* MS | Progress */

.pure-material-slider>input::-ms-fill-upper {
    border-radius: 1px;
    height: 2px;
    background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.24);
}


/* MS | Thumb */

.pure-material-slider>input::-ms-thumb {
    appearance: none;
    margin: 0 17px;
    border: none;
    border-radius: 50%;
    height: 2px;
    width: 2px;
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
    transform: scale(6, 6);
    transition: box-shadow 0.2s;
}


/* MS | Hover, Focus */

.pure-material-slider:hover>input::-ms-thumb {
    box-shadow: 0 0 0 2px rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.04);
}

.pure-material-slider>input:focus::-ms-thumb {
    box-shadow: 0 0 0 2px rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.12);
}

.pure-material-slider:hover>input:focus::-ms-thumb {
    box-shadow: 0 0 0 2px rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.16);
}


/* MS | Active */

.pure-material-slider>input:active::-ms-thumb {
    box-shadow: 0 0 0 2px rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.24) !important;
}


/* MS | Disabled */

.pure-material-slider>input:disabled::-ms-fill-lower {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

.pure-material-slider>input:disabled::-ms-fill-upper {
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    opacity: 0.38;
}

.MuiDropzonePreviewList-image {
    width: 100% !important;
    height: 100% !important;
}

.MuiDropzonePreviewList-imageContainer {
    margin: 0px auto !important;
    padding-bottom: 5% !important;
}

.pure-material-slider>input:disabled::-ms-thumb {
    background-color: rgb(var(--pure-material-onsurface-rgb, 0, 0, 0));
    box-shadow: 0 0 0 1px rgb(var(--pure-material-surface-rgb, 255, 255, 255)) !important;
    transform: scale(4, 4);
}
.makeStyles-inputInput-9 {
    width: 100% !important;
    border-radius: 10px !important;
    border: 1px solid #ced4da !important;
    outline: none !important;
    box-shadow: none !important;
}

.image-pick-file {
    margin-bottom: 11rem;
}

.image-pick-file .MuiDropzoneArea-text {
    display: none;
}

.image-pick-file .MuiDropzoneArea-icon {
    margin-top: 5rem;
    width: 64px;
    height: 64px;
}

.image-pick-file .MuiDropzonePreviewList-image {
    height: 200px;
    width: 100%;
}

.image-pick-file .MuiDropzoneArea-root {
    border-radius: 10px;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 5%);
}

.makeStyles-search-6 {
    border-radius: 10px;
    border: none !important;
    width: 100% !important;
}

.title-description {
    margin-top: 0rem;
}

.title-description .form-label {
    font-size: 16px;
    color: gray;
}

.image-logo-picker {
    text-align: center;
    margin-top: 1rem;
}

.image-logo-picker p {
    font-size: 18px;
    font-weight: 500;
}

.image-logo-picker .MuiDropzoneArea-text {
    display: none;
}

.image-logo-picker .MuiDropzoneArea-icon {
    width: 64px;
    height: 64px;
}

.MuiDropzoneArea-root {
    background-color: #fafafa !important;
}

.image-logo-picker .MuiDropzoneArea-root {
    border-radius: 50%;
    width: 280px;
    height: 270px;
    border: dashed;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    border-color: rgba(0, 0, 0, 0.12);
    background-color: #fff;
    text-align: right;
    display: inline-block;
}

.image-featured-picker {
    text-align: center;
}

.image-featured-picker p {
    font-size: 18px;
    font-weight: 500;
}

.image-featured-picker .MuiDropzoneArea-text {
    display: none;
}

.image-featured-picker .MuiDropzoneArea-icon {
    margin-top: 5rem;
    width: 64px;
    height: 64px;
}

.image-featured-picker .MuiDropzoneArea-root {
    width: 60%;
    border: dashed;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    min-height: 250px;
    border-color: rgba(0, 0, 0, 0.12);
    background-color: #fff;
    text-align: right;
    display: inline-block;
}
.sellButton{
    font-size: larger !important;
    cursor: pointer !important;
}
.border-style-none {
    border-bottom-style: none;
    border-bottom: none;
}

.border-style-none .MuiSvgIcon-root {
    color: darkgray;
}

.tabs-background-theme .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #d8eaf5;
    /* border-radius: 10px;
    border: 1px solid lightgray; */
    padding: 0rem 0rem !important;
    margin-top: 7px;
}
.nav-link {
    padding: .5rem 0rem !important;
}
.nav-pills .nav-link {
    padding: 10px !important;
}

.auction-end {
    font-size: 20px;
    font-weight: 500;
    padding: 7px;
}
 .preview-card-margin {
    margin-top: 20px ;
}


article {
    position: relative;
    
    box-sizing: border-box;
  }
  
  article .add-checked-option {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    line-height: 25px;
    transition: .5s ease;
    margin-top: 20px;
  }
  
  article input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  input[type=checkbox]:checked ~ div {
    background-color: #d8eaf5;
    border-radius: 10px;
  }
  
  
  
  @keyframes slidein {
    from {
      margin-top: 100%;
      width: 300%;
    }
  
    to {
      margin: 0%;
      width: 100%;
    }
  }
.row-define {
    margin: 20px;
}

.upload-image-circle .MuiGrid-spacing-xs-8 {
    margin: 0px !important;
    justify-content: center;
    width: calc(80% + 64px);
}

.upload-image-circle .MuiGrid-spacing-xs-8>.MuiGrid-item {
    padding: 0px !important;
}

.rectangle-image-picker {
    margin-top: 3rem;
}

.forms-label-title .form-label {
    font-size: 16px;
    color: gray;
    margin-top: 10px;
}

.forms-label-title .form-control {
    font-size: 15px;
    color: gray;
    padding: 0.7rem .75rem;
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 5%);
    border-radius: 10px;
}

.dropzone-image-picker .MuiDropzoneArea-root {
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 5%);
    border-radius: 10px;
    background-color: white !important;
}

.drop-file-pick {
    margin-bottom: 20px;
}

.previewComponent .MuiDropzoneArea-root {
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 5%);
    border-radius: 10px;
    background-color: white !important;
}

.previewComponent .MuiDropzoneArea-text {
    color: gray;
}

.active-link .active,
.btn:hover {
    border: 1px solid lightgray;
    background-color: #eaf4fa;
}

.dropdown-filter .makeStyles-root-78 .MuiTextField-root {
    width: 20ch;
    margin: 1px;
    margin-top: 12px;
}

.dropdown-filter .MuiOutlinedInput-input {
    padding: 10.5px 14px;
}

.time-picks .react-time-picker {
    display: inline-flex;
    position: relative;
    padding: 8px;
}

.time-picks .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid lightgray;
    padding: 7px 0px;
}

.buyer-address .form-control {
    padding: 0.775rem .75rem;
}


/* Listings css */

.listings .pl-3,
.listings .px-3 {
    padding-left: 1rem !important;
    margin-top: 11px !important;
    height: 60px !important;
}

.listings dl,
.listings ol,
.listings ul {
    margin-top: 0;
    margin-bottom: -2rem !important;
}

.listings ul {
    font-size: 10px;
    margin-left: -10px;
}

.listings li {
    font-size: 10px;
    margin-bottom: -8px;
    text-align: center;
}

.listings .wrapper {
    width: 1300px;
}

.listings .card {
    border: none;
    height: 100%;
    padding: 2%;
    margin-bottom: 10px;
}

.listings .title,
.listings .Card-Text,
.listings p,
.listings .row,
.listings .h3 {
    color: black;
}

.listings h3 {
    color: black;
}

.listings .container {
    justify-content: center;
    flex-direction: row;
}

.dpimage {
    float: left;
    width: 20%;
    height: 20%;
}

.listings .head {
    background-color: #f3f9ff;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
    height: 60px;
}

.listings .btnsss {
    text-align: center;
    margin-top: 150px;
    margin-bottom: 30px;
    cursor: pointer;
    outline: none;
}

.listings .btn-primary {
    color: #fff !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
    outline: none;
    width: 70% !important;
}

.listings .Link {
    color: #eb7f73;
}

.listings .but-ns Button {
    width: 70%;
}

.order-buttons ul li {
    display: inline-block;
    padding: 5px;
}

.listings .card:hover,
.listings .card:focus,
.listings .card:active {
    background-color: rgb(214, 234, 240);
}

.order-buttons .btn-outline-info {
    border-radius: 10px !important;
}

.order-buttons .btn p {
    margin-bottom: 0px;
    font-size: 14px;
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
    background-color: rgb(214, 234, 240) !important;
}

.live-auctions-mobileview .react-multi-carousel-list {
    display: flex !important;
    align-items: center !important;
    overflow: hidden !important;
    position: relative;
}

.clear-all-buttons ul li {
    display: inline-block;
    padding: 8px;
}
.clear-all-buttons ul li .MuiButton-outlinedPrimary {
    border-radius: 10px;
    
}

.clear-all-buttons .btn {
    font-size: 14px;
    border-radius: 10px;
}

.clear-all-buttons .btn:hover {
    color: white;
    background-color: rgb(139, 190, 233);
}

.clear-all-buttons .MuiSvgIcon-root {
    color: white;
    font-size: 1rem;
}

.MuiGrid-grid-xs-11 {
    max-width: 100% !important;
}

.react-multiple-carousel__arrow--left{
    left: calc(0% + 10px) !important;
}
.react-multiple-carousel__arrow--right{
    right: calc(0% + 10px) !important;
}
/* .homepage-explore .react-multiple-carousel__arrow--right {
    right: calc(4% + 14px);
} */

.bio-date-edit .form-label {
    margin-bottom: 0rem;
    font-size: 14px;
    font-weight: 600;
    color: gray;
}

.edit-profile-icons .MuiSvgIcon-root {
    margin-left: 20px;
    width: 0.7em !important;
}

.bio-date-edit .form-control {
    font-size: 12px;
}

.img-src-card ._1DghVSjROos1S_s0jPt9TQ,
._33ob_2Sxjrzz9VgWuEn9xb {
    width: 100% !important;
}

.img-src-card ._2oSbenaQCQZPXOD75Sj6nE {
    width: 100% !important;
    height: 65px !important;
    border-radius: 50% !important;
}

.img-src-card ._1Fq_tZ_l7SwH_r_a_SNOtA .GNSzzq7thIGJvQKQMnUF4 {
    display: none !important;
}

.card-text p {
    display: none;
}

.modal-footer {
    justify-content: center !important;
}

.modal-header p {
    text-align: center;
    justify-content: center;
    position: absolute;
    margin: 20px;
    font-size: 22px;
}

.input-group-text {
    padding: .3rem 0.9rem;
    border-radius: 0px;
}

.modal-body .form-control {
    border-radius: 0px;
}

.modal-body {
    padding: 2rem;
}
.buy-span span .MuiButton-root {
    background-color: #0069d9;
    color: white !important;
    margin-top: 5px;
    margin-right: 5px;
}
.right-content-data ul li a span .light-shadow {
    color: white !important;
    
}
.history-trade .table-responsive {
    margin-bottom: 0% ;
}
.coming-soon-icon {
    margin-top: 5rem;
    text-align:center;
}
.icons-soon img {
    width: 20%;
}
.icons-soon h5.h5 {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
}

.coming-soon-icon .light-shadow {
    margin-top: 20px;
    padding: 2px 14px;
    font-size: 50px;
    background-color: transparent;
    color: gray;
    letter-spacing: 4px;
    font-weight: 600;
    border: 2px solid lightgray;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    line-height: 70px;
}
.details-text {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
}

@media screen and (max-width: 400px) {
    .listings .Card.Img {
        position: absolute;
    }
    .listings .card-img-top {
        width: 21% !important;
        position: absolute !important;
        padding: 10px !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
    }
    .listings .card-title {
        margin-bottom: 0.75rem !important;
        margin-top: 46px !important;
    }
    .marginOpx ul {
        margin-right: 0px !important;
    }
    .grid_title{
        margin-top: 50px !important;
    }
}

@media screen and (max-width: 450px) {
    .listings .Card.Img {
        position: absolute;
    }
    .listings .card-img-top {
        width: 21% !important;
        position: absolute !important;
        padding: 10px !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
    }
    .listings .card-title {
        margin-bottom: 0.75rem !important;
        margin-top: 46px !important;
    }
}
@media screen and (max-width: 812px) {
    .filterMob{
        display: none !important;
    }
}
@media screen and (max-width: 600px) {
    .listings .Card.Img {
        position: absolute;
    }
    .filterMob{
        display: none;
    }
    .price-buy ul li a span {
        font-size: 10px;
        font-weight: 500;
    }
    .jss10{
        display:none !important; 
    }
    .navIcon{
        display:none !important;
    }
    .buy-span span .MuiButton-root{
        padding: 5px;
    }
    .listings .card-img-top {
        width: 21% !important;
        position: absolute !important;
        padding: 10px !important;
        margin-left: 0px !important;
        margin-top: 0px !important;
    }
    .listings .card-title {
        margin-bottom: 0.75rem !important;
        margin-top: 80px !important;
    }
}


/* Listings css */

.sort-search {
    margin-bottom: 20px;
}
.empty-icons {
    text-align: center;
    margin: 20px;
}
.empty-icons .MuiSvgIcon-root {
    font-size: 4rem;
}
.about-span-image img {
    width: 35px;
}

h5.h5 {
    font-size: 16px;
    font-weight: 600;
}
@media screen and (min-width: 401px) and (max-width: 1920px) {
    .marginOpx ul {
        margin-left: 13px !important;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1920px) {
    .logo-image img {
        width: 150px !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 410px) {
    .MuiButton-label {
        margin-left: 0rem !important;
    }
    .MuiPopover-paper {
        margin-top: 14% !important;
    }
}

@media screen and (min-width: 411px) and (max-width: 575px) {
    .MuiButton-label {
        margin-left: 0rem !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 350px) {
    .MuiTypography-h3 {
        margin-left: 5rem !important;
    }
    .MuiBox-root-44 {
        margin-left: 6rem;
    }
    .MuiBox-root-45 {
        margin-left: 3rem;
        display: flex;
    }
}

@media screen and (min-width: 351px) and (max-width: 400px) {
    .MuiTypography-h3 {
        margin-left: 0px !important;
    }
    .MuiBox-root-44 {
        margin-left: 8rem;
    }
    .MuiBox-root-45 {
        margin-left: 6rem;
        display: flex;
    }
}

@media screen and (min-width: 401px) and (max-width: 450px) {
    .MuiTypography-h3 {
        margin-left: 0px !important;
    }
    .MuiBox-root-44 {
        margin-left: 10rem;
    }
    .MuiBox-root-45 {
        margin-left: 7rem;
        display: flex;
    }
}

@media screen and (min-width: 451px) and (max-width: 500px) {
    .MuiTypography-h3 {
        margin-left: 0px !important;
    }
    .MuiBox-root-44 {
        margin-left: 10rem;
        display: flex;
    }
    .MuiBox-root-45 {
        margin-left: 7rem;
        display: flex !important;
    }
}

@media screen and (min-width: 501px) and (max-width: 550px) {
    .MuiTypography-h3 {
        margin-left: 10rem !important;
    }
    .MuiBox-root-44 {
        margin-left: 12rem;
    }
    .MuiBox-root-45 {
        margin-left: 8rem;
        display: flex;
    }
}

@media screen and (min-width: 551px) and (max-width: 600px) {
    .MuiTypography-h3 {
        margin-left: 0px !important;
    }
    .MuiBox-root-44 {
        margin-left: 13rem;
    }
    .MuiBox-root-45 {
        margin-left: 10rem;
        display: flex;
    }
   
}
@media screen and (min-width: 320px) and (max-width: 575px) {
   
    .nfts-responsive {
        margin-left: 0rem !important;
    }
    .live-auctions-mobileview .MuiGrid-root .MuiGrid-grid-sm-12 {
        width: 100% !important;
        height: 100px !important;
    }
    .footer-log img {
        width: 25% !important;
    }
    .PlainNavigationMenu-item-18 {
        display: none !important;
    }
    .PlainNavigationMenu-item-343 {
        display: none !important;
    }
    .create-assets .btn.light-shadow {
        margin-top: 10px;
    }
    .image-logo-picker .MuiDropzoneArea-root {
        width: 250px;
        height: 250px;
    }
    .summary-section {
        margin-top: 10px;
    }
    .title-detail {
        margin-top: 10px;
    }
    .accordion-label p {
        font-size: 13px;
    }
    .accordion-label label {
        font-size: 12px;
    }
    .file-title p {
        margin-top: 10px;
    }
    .description-trade {
        height: 100%;
    }
    .responsive-align {
        margin-top: 4rem;
    }
    .responsive-alignment {
        margin-top: 6rem;
    }
    .responsive-view {
        margin-top: 0rem;
    }
    .info-user {
        margin-left: 40%;
    }
    .change-data .light-shadow {
        width: 25%;
    }
    .NikiCategoryMenu-item-50 {
        display: block !important;
    }
    .makeStyles-title-37 {
        display: block !important;
    }
    .makeStyles-label-36 {
        display: block !important;
    }
    .BootstrapEmailSubscribe-form-41 {
        display: contents !important;
    }
    /* .BootstrapEmailSubscribe-form-41 {
        display: contents !important;
    }
    .makeStyles-title-37 {
        display: block !important;
    }
    .makeStyles-label-36 {
        display: block !important;
    }
    .MuiBox-root-44 {
        width: 200% !important;
    }
    .MuiBox-root-45 {
        width: 200% !important;
    }
    .NikiCategoryMenu-item-50 {
        display: block !important;
    } */
    .Footer-communinty .makeStyles-title-218 {
        display: block;
    }
    .Footer-communinty .makeStyles-label-217 {
        display: block;
    }
    .Footer-communinty .BootstrapEmailSubscribe-form-222 {
        display: contents;
    }
    .img-src {
        border-radius: 10px;
        border: 1px solid gray;
        height: 100%;
    }
    .image-gallery-slide.center {
        position: relative !important;
        height: 100% !important;
    }
    .image-gallery-slide {
        width: 100% !important;
    }
    .right-content-data ul li {
        margin: 1%;
    }
    .history-trade {
        height: 100%;
    }
    .forms-tag {
        padding: 5%;
    }
    .forms-tag .form-control {
        width: 90%;
    }
    .facebook-icon .light-shadow,
    .google-icon .light-shadow,
    .email-icon .light-shadow,
    .meta-icon .light-shadow,
    .log-in .light-shadow {
        font-size: 1rem;
        margin-bottom: 5%;
    }
    .NikiCategoryMenu-item-40 {
        display: block !important;
    }
    .makeStyles-title-27 {
        display: block !important;
    }
    .makeStyles-label-26 {
        display: block !important;
    }
    .BootstrapEmailSubscribe-form-31 {
        display: contents !important;
    }
    /* .MuiBox-root-34 {
        margin-left: 43% !important;
    width: 113% !important;
    } */
    /* .MuiBox-root-35 {
        margin-left: 43% !important;
    width: 113% !important;
    } */
    .makeStyles-title-58 {
        display: block !important;
    }
    .makeStyles-label-57 {
        display: block !important;
    }
    .BootstrapEmailSubscribe-form-62 {
        display: contents !important;
    }
    .NikiCategoryMenu-item-71 {
        display: block !important;
    }
    .MuiBox-root-65 {
        text-align: center;
        margin-bottom: 16px;
        display: block !important;
        /* float: right !important; */
    }
    /* .MuiBox-root-66 {
        text-align: center;
    display: inline-flex !important;
    float: right !important;
    width: 5%;
    margin-top: 24% !important;
    } */
    .MuiBox-root-153 {
        text-align: center;
        margin-bottom: 16px;
        /* display: block !important; */
        float: right !important;
    }
    .MuiBox-root-154 {
        text-align: center;
        display: inline-flex !important;
        /* float: right !important; */
        width: 5%;
        /* margin-top: 24% !important; */
    }
    /* .MuiBox-root-92 {
        margin-left: 43% !important;
    width: 113% !important;
    } */
    /* .MuiBox-root-91 {
        margin-left: 43% !important;
        width: 113% !important;
    } */
    .footer-log img {
        margin-top: 2%;
        margin-bottom: 10%;
    }
    .makeStyles-title-155 {
        display: block !important;
    }
    .makeStyles-label-154 {
        display: block !important;
    }
    .BootstrapEmailSubscribe-form-159 {
        display: contents !important;
    }
    .MuiBox-root-162 {
        margin-top: 16px;
        justify-content: center;
        display: block;
        /* width: 167%; */
        text-align: center;
    }
    .MuiBox-root-163 {
        margin-top: 16px;
        text-align: center;
        justify-content: center;
        /* width: 163%; */
    }
    .logo-image img {
        display: none;
    }
    .jss168 {
        display: block !important;
    }
    .jss155 {
        display: block !important;
    }
    .jss162 {
        display: block !important;
        text-align: center;
        width: 188% !important;
    }
    .jss154 {
        display: block !important;
    }
    .jss159 {
        display: contents !important;
    }
    .jss163 {
        margin-top: 16px;
        display: block !important;
        width: 189% !important;
    }
    .jss5 {
        width: 200% !important;
        margin-left: 0% !important;
    }
    .logo-text {
        font-size: 25px;
        font-weight: 500;
        display: block;
    }
    .makeStyles-search-5 {
        width: 200% !important;
    }
    .SwipeableTextMobileStepper-img-20 {
        height: auto !important;
    }
    .makeStyles-sectionDesktop-10 {
        display: flex !important;
        margin-left: 35% !important;
    }
    .NikiCategoryMenu-item-159 {
        display: block !important;
    }
    .makeStyles-title-146 {
        display: block !important;
    }
    .makeStyles-label-145 {
        display: block !important;
    }
    .BootstrapEmailSubscribe-form-150 {
        display: contents !important;
    }
    .NikiCategoryMenu-item-49 {
        display: block !important;
    }
    .makeStyles-title-36 {
        display: block !important;
    }
    .makeStyles-label-35 {
        display: block !important;
    }
    .BootstrapEmailSubscribe-form-40 {
        display: contents !important;
    }
    /* .MuiBox-root-43 {
        width: 200%;
    }
    .MuiBox-root-44 {
        width: 200%;
    } */
    .filterMob{
        display: none;
    }
    .jss10 {
        margin-left: 35% !important;
    }
    .jss9 {
        display: none !important;
        justify-content: center;
    }
    .jss5 {
        width: 200% !important;
    }
    .jss19 {
        height: 150px !important;
    }
    .jss10 {
        /* display: flex !important; */
    }
    /* .support-footer  {
        margin-top: -3rem !important;
    } */
    .footer-log {
        text-align: center !important;
    }
    .copy-right {
        text-align: center;
    }
    .copy-right .NikiCategoryMenu-item-327 {
        text-align: center !important;
    }
    .copy-right .CategoryItem {
        text-align: center !important;
    }
    .MuiPopover-paper {
        margin-top: 10% !important;
        width: 37%;
    }
    .MuiMenuItem-root p {
        margin-top: 0px;
        margin-bottom: 0px;
        /* text-align: center !important; */
        width: 100%;
    }
    .jss188 {
        display: block !important;
    }
    .jss175 {
        display: inherit !important;
    }
    .jss174 {
        display: grid !important;
    }
    .jss179 {
        display: inherit !important;
    }
    .MuiGrid-grid-xs-6 {
        text-align: left !important;
        flex-basis: 50% !important;
    }
    .jss183 {
        width: 170% !important;
    }
    .jss182 {
        width: 170% !important;
    }
    .jss173 {
        width: 100% !important;
    }
    .jss5 {
        margin-left: 0px !important;
    }
    .expand-bar {
        display: block !important;
    }
    .search-large {
        display: none !important;
    }
    .top-content {
        display: none;
    }
    a {
        text-decoration: none;
    }
    .logo-image img {
        width: 150px;
    }
    .expand-title {
        margin-top: 23%;
    }
    .img-profile-center .MuiBadge-root {
        text-align: center;
        margin-top: -4rem;
        position: relative;
    }
    .logo-image-text {
        display: block;
    }
    .logo-image-text img {
        width: 100%;
        /* margin-left: -70%; */
    }
    .NikiCategoryMenu-item-168 {
        justify-content: center;
        text-decoration: none;
    }
    .footer_wrap .makeStyles-title-84 {
        display: block !important;
    }
    .footer_wrap .makeStyles-label-83 {
        display: block !important;
    }
    .footer_wrap .BootstrapEmailSubscribe-form-88 {
        display: inline-block !important;
    }
    .footer_wrap .NikiCategoryMenu-item-97 {
        display: block !important;
    }
    .makeStyles-search-6 {
        width: 200% !important;
    }
    .search-bar {
        position: relative !important;
        padding: 0%;
        width: 100%;
    }
}

@media screen and (min-width: 375px) and (max-width: 450px) {
    .jss173 {
        margin-left: 10% !important;
    }
}

@media screen and (min-width: 451px) and (max-width: 520px) {
    .jss173 {
        margin-left: 15% !important;
    }
}

@media screen and (min-width: 521px) and (max-width: 580px) {
    .jss173 {
        margin-left: 20% !important;
    }
}

@media screen and (min-width: 280px) and (max-width: 300px) {
    .jss173 {
        width: 40ch !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 992px) {
    .makeStyles-sectionDesktop-10 {
        display: flex !important;
    }
    .jss10 {
        display: flex !important;
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .makeStyles-sectionDesktop-10 {
        display: flex !important;
    }
    .jss10 {
        display: flex !important;
    }
}


/* .active-tabs input {
    opacity: 0;
    display: none;
    visibility: hidden;
}

.btn {
    background: #1babbb;
    color: #fff;
    cursor: pointer;
    display: block;
    float: left;
    font-family: "open sans";
    font-size: 13px;
    height: 40px;
    line-height: 35px;
    margin-right: 1px;
    text-align: center;
    width: 100px;
    opacity: 0.8;
    transition: all 0.4s;
} */

.check-order-cards .selected-content h5 {
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
    color: #686d73;
}

.check-order-cards .selected-label {
    position: relative;
}

.check-order-cards .selected-label input {
    display: block;
}

.check-order-cards .selected-label .icon {
    width: 20px;
    height: 20px;
    border: solid 2px #e3e3e3;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 15px;
    transition: .3s ease-in-out all;
    transform: scale(1);
    z-index: 1;
}

.check-order-cards .selected-label .icon:before {
    content: "\f00c";
    position: absolute;
    width: 100%;
    height: 100%;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    color: #000;
    text-align: center;
    opacity: 0;
    transition: .2s ease-in-out all;
    transform: scale(2);
}

.check-order-cards .selected-label input:checked+.icon {
    background: #3057d5;
    border-color: #3057d5;
    transform: scale(1.2);
}

.check-order-cards .selected-label input:checked+.icon:before {
    color: #fff;
    opacity: 1;
    transform: scale(.8);
    right: 0;
}

.check-order-cards .selected-label input:checked~.check-order-cards .selected-content {
    box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5);
    border: solid 2px #3057d5;
}


/* .active-tabs {
    width: 800px;
    padding: 10px;
    position: relative;
    margin: 20px auto;
}

.active-tabs input {
    opacity: 0;
    display: none;
    visibility: hidden;
}




.active-tabs input:checked + label {
    background: #fff;
    opacity: 1;
    box-shadow: 1px 0 0 0 rgba(0,0,0,0.3);
    color: #1babbb;
}




.btn-1:checked ~ .tabs-container .tab-1 ,
.btn-2:checked ~ .tabs-container .tab-2 ,
.btn-3:checked ~ .tabs-container .tab-3 
 {
    position: relative;
    visibility: visible;
    top: 0;
    left: 0;
    opacity: 1;
} */