// .PlainNavigationMenu-item-18 {
//     color: rgba(0, 0, 0, 0.54);
//     cursor: pointer;
//     display: flex;
//     padding: 20px 20px;
//     transition: all 0.2s ease-out 0s;
//     align-items: center;
//     flex-shrink: 0;
//     border-radius: 4px;
//     text-decoration: none;
//     text-decoration: underline;
//     text-decoration-color: #0084ff;
//     text-decoration-thickness: 2px;
//     text-underline-offset: 20px;
// }


.grid_title{
    font-family: 'Rubik', sans-serif !important;
    font-weight:700;
    text-align:center;
    margin-top: 100px !important;
    &::after{
        content: "";
        display: block;
        border-top: .3rem solid #0084ff;
        width: 4rem;
        margin: 25px auto 0 auto;
        transform: translateY(-1rem);
    }
}
.blog_title{
    font-family: 'Rubik', sans-serif !important;
    font-weight:700;
    text-align:center;
    margin-top: 20px !important;
    &::after{
        content: "";
        display: block;
        border-top: .3rem solid #0084ff;
        width: 4rem;
        margin: 25px auto 0 auto;
        transform: translateY(-1rem);
    }
}
.gray_border{
    border: 2px solid #ebebeb;
    border-radius: 12px;
}
.count-down{
    box-shadow: 11px 15px 29px 0 rgb(48 48 48 / 7%);
    // background: #ccc;
    border-radius: 7px;
    border: solid 1px #ccc;
}
.red_color_oction{
    color: crimson;
}
.blue_border{
     border: 2px solid #0084ff;
    border-radius: 12px;
}
.timer-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  
    .timer{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px 12px;
    }
    .number{
        font-size: 22px;
    }
}

.footer_wrap{
    background: #f3f6fa;
    margin-top: 60px;
}